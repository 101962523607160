<template>
  <div>
    <a-modal v-model="modalVisible" :title="modalTitle" :width="1000" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalVisible=false">{{showType=='detail' ? '关闭' : '取消'}}</a-button>
        <a-button v-if="showType=='add' || showType=='edit'" type="primary" @click="confirm()">提交</a-button>
      </template>
      <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" :model="formData" :rules="formRules" :label-col="{span:8}" :wrapper-col="{span:16}">
<!--          <h3 style="font-size: large;font-weight: bold; color: #0388fd;">基础信息</h3>-->
          <a-form-model-item label="项目名称" prop="monitorpointnum">
            <a-select :disabled="showType=='detail'" v-model="formData.monitorpointnum" placeholder="请选择">
              <a-select-option v-for="(item, index) in monitorpointList" :key="index" :value="item.monitorpointnum">{{item.monitorpointname}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="设备名称" prop="equipment_num">
            <a-select show-search :filter-option="filterOption" :disabled="showType=='detail'" v-model.trim="formData.equipment_num"  @change="getEquipDirectories()">
              <a-select-option v-for="(item,index) in equipmentNumList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="设备类型" prop="equipment_type">
            <a-select :disabled="true" v-model="formData.equipment_type">
              <a-select-option v-for="(item, index) in deviceCodeList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="设备编号" prop="equipment_num">
            <a-input :disabled="true" v-model.trim="formData.equipment_num" />
          </a-form-model-item>
          <a-form-model-item label="用途" prop="purpose">
            <textarea :disabled="true" v-model.trim="formData.purpose" style="width: 305px"/>
          </a-form-model-item>
          <a-form-model-item></a-form-model-item>
          <a-form-model-item label="场景" prop="run_scene">
            <a-select :disabled="showType=='detail'" v-model="formData.run_scene">
              <a-select-option v-for="(item, index) in equipmentSceneList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="模式" prop="pattern">
            <a-select :disabled="showType=='detail'" v-model="formData.pattern">
              <a-select-option v-for="(item, index) in equipmentModeList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="时段一" prop="" style="width: 460px;" :required="true">
                <a-time-picker :disabled="showType=='detail'" :value="interval11?moment(interval11, 'HH:mm'):null" format="HH:mm" @change="interval1OnChange1" style="width: 144px;" />~
                <a-time-picker :disabled="showType=='detail'" :value="interval12?moment(interval12, 'HH:mm'):null" format="HH:mm" @change="interval1OnChange2" style="width: 144px"/>
                <div v-if="isConfirm&&(!interval11||!interval12)" style="color: red">请选择时段一</div>
              </a-form-model-item>
              <a-form-model-item label="时段二" prop="interval2" style="width: 460px;">
                <a-time-picker :disabled="showType=='detail'" :value="interval21?moment(interval21, 'HH:mm'):null" format="HH:mm" @change="interval2OnChange1" style="width: 144px"/> ~
                <a-time-picker :disabled="showType=='detail'" :value="interval22?moment(interval22, 'HH:mm'):null" format="HH:mm" @change="interval2OnChange2" style="width: 144px"/>
              </a-form-model-item>
              <a-form-model-item label="时段三" prop="interval3" style="width: 460px;">
                <a-time-picker :disabled="showType=='detail'" :value="interval31?moment(interval31, 'HH:mm'):null" format="HH:mm" @change="interval3OnChange1" style="width: 144px"/> ~
                <a-time-picker :disabled="showType=='detail'" :value="interval32?moment(interval32, 'HH:mm'):null" format="HH:mm" @change="interval3OnChange2" style="width: 144px"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="说明" prop="description" style="width: 460px;">
                <textarea :disabled="showType=='detail'" v-model.trim="formData.description"  style="width: 305px;height: 105px"/>
              </a-form-model-item>
            </a-col>
          </a-row>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment'
import { mapState, mapGetters } from 'vuex'
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import {
  addOperationPlan, getEquipDirectoriesList, getEquipDirectoriesListByCondition,
  getOperationPlanListByCondition, modifyOperationPlan,
} from "A/facilityandequipment";
import {getMonitorPointNameListByCondition} from "A/monitoring";
import {getCache, getTimeRange} from "U/index";
export default {
  mixins: [areaselect, deptselect],
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      modalBodyStyle: {
        height: '675px',
        overflowY: 'auto',
      },
      modalVisible: false,
      formData: {
        directories_id: '',
        monitorpointnum: '',
        equipment_type: '',
        equipment_name: '',
        equipment_num: '',
        purpose: '',

        run_scene:'',
        pattern:'',
        interval1:'',
        interval2:'',
        interval3:'',
        description:'',
        status:'1',
      },
      //这里面的数据属于必填项
      formRules: {
        monitorpointnum: [{required: true, message: '请选择项目名称'}],
        equipment_num: [{required: true, message: '请选择设备名称'}],
        run_scene: [{required: true, message: '请选择场景'}],
        pattern: [{required: true, message: '请选择模式'}],
        // interval1: [{required: true, message: '请输入时段一'}],
      },
      validateStatus:false,
      monitorpointList:[],
      deviceCodeList:[],
      equipmentSceneList:[],
      equipmentModeList:[],
      equipmentNumList:[],
      interval11:'',
      interval12:'',
      interval21:'',
      interval22:'',
      interval31:'',
      interval32:'',
      isConfirm:false,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return "运行管理"
    },
    ...mapGetters(['uploadHeaders', 'uploadFacilityEquipment']),
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.resetData();
        this.getMonitorPointNameList();
        this.getEquipDirectoriesList();
        this.initDetail();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetData();
        this.$emit('update:visible', false);
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
    let dictionary = getCache('dictionary', true);
    if(dictionary) {
      this.deviceCodeList = dictionary.deviceCodeList
      this.equipmentSceneList = dictionary.equipmentSceneList
      this.equipmentModeList = dictionary.equipmentModeList
    }
    this.initDeptOptionsAll();
  },
  methods: {
    interval1OnChange1(time, timeString){
      this.interval11=timeString
    },
    interval1OnChange2(time, timeString){
      this.interval12=timeString
    },
    interval2OnChange1(time, timeString){
      this.interval21=timeString
    },
    interval2OnChange2(time, timeString){
      this.interval22=timeString
    },
    interval3OnChange1(time, timeString){
      this.interval31=timeString
    },
    interval3OnChange2(time, timeString){
      this.interval32=timeString
    },
    getMonitorPointNameList(){
      getMonitorPointNameListByCondition({userdepsid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.monitorpointList=res.item
        }
      })
    },
    resetData() {
      if (this.$refs.modalForm) {
        this.$refs.modalForm.resetFields();
      }
      this.interval11=null
      this.interval12=null
      this.interval21=null
      this.interval22=null
      this.interval31=null
      this.interval32=null
    },
    initDetail() {
      if (this.detailData && this.detailData.directories_id) {
        if(this.showType == 'edit' || this.showType == 'detail') {
          this.$nextTick(() => {
            let params = {
              operation_plan_id: this.detailData.operation_plan_id
            }
            getOperationPlanListByCondition(params).then(res => {
              if (res && res.returncode == '0') {
                this.formData = res.item[0];
                // 特别数据回显
                this.interval11=res.item[0].interval1?moment(res.item[0].interval1.split("~")[0],"HH:mm"):""
                this.interval12=res.item[0].interval1?moment(res.item[0].interval1.split("~")[1],"HH:mm"):""
                this.interval21=res.item[0].interval2?moment(res.item[0].interval2.split("~")[0],"HH:mm"):""
                this.interval22=res.item[0].interval2?moment(res.item[0].interval2.split("~")[1],"HH:mm"):""
                this.interval31=res.item[0].interval3?moment(res.item[0].interval3.split("~")[0],"HH:mm"):""
                this.interval32=res.item[0].interval3?moment(res.item[0].interval3.split("~")[1],"HH:mm"):""
              }
            })
          })
        }
      }
    },
    confirm() {
      if (this.showType == 'add' || this.showType == 'edit') {
        this.isConfirm = true
        this.$refs.modalForm.validate(valid => {
          if (valid&&this.interval11&&this.interval12) {
            if(this.interval11||this.interval12) {
              if (this.interval11.toString().length === 5 && this.interval12.toString().length === 5) {
                this.formData.interval1 = this.interval11 + "~" + this.interval12
              } else if (this.interval11.toString().length === 5 && this.interval12.toString().length != 5) {
                this.formData.interval1 = this.interval11 + "~" + moment(this.interval12).format("HH:mm")
              } else if (this.interval11.length !== 5 && this.interval12.length === 5) {
                this.formData.interval1 = moment(this.interval11).format("HH:mm") + "~" + this.interval12
              } else {
                this.formData.interval1 = moment(this.interval11).format("HH:mm") + "~" + moment(this.interval12).format("HH:mm")
              }
            }
            if(this.interval21||this.interval22) {
              if (this.interval21 && this.interval21.toString().length === 5 && this.interval22.toString().length === 5) {
                this.formData.interval2 = this.interval21 + "~" + this.interval22
              } else if (this.interval21.toString().length === 5 && this.interval22.toString().length != 5) {
                this.formData.interval2 = this.interval21 + "~" + moment(this.interval22).format("HH:mm")
              } else if (this.interval21.length !== 5 && this.interval22.length === 5) {
                this.formData.interval2 = moment(this.interval21).format("HH:mm") + "~" + this.interval22
              } else {
                this.formData.interval2 = moment(this.interval21).format("HH:mm") + "~" + moment(this.interval22).format("HH:mm")
              }
            }
            if(this.interval31||this.interval32) {
              if (this.interval31 && this.interval31.toString().length === 5 && this.interval32.toString().length === 5) {
                this.formData.interval3 = this.interval31 + "~" + this.interval32
              } else if (this.interval31.toString().length === 5 && this.interval32.toString().length != 5) {
                this.formData.interval3 = this.interval31 + "~" + moment(this.interval32).format("HH:mm")
              } else if (this.interval31.length !== 5 && this.interval32.length === 5) {
                this.formData.interval3 = moment(this.interval31).format("HH:mm") + "~" + this.interval32
              } else {
                this.formData.interval3 = moment(this.interval31).format("HH:mm") + "~" + moment(this.interval32).format("HH:mm")
              }
            }
            let params = {
              ...this.formData,
            };
            if (this.showType == 'add') {
              getOperationPlanListByCondition({monitorpointnum:this.formData.monitorpointnum,equipment_num:this.formData.equipment_num,status:1}).then(res => {
                if (res && res.returncode == '0') {
                  if(res&&res.item.length>0){
                    // this.$confirm('查询到该设备已存在运行计划,系统将要把当前的运行计划置为历史，然后为该设备新建运行计划,请问您确定要这样做吗？',{
                    //   type:'warning',
                    //   centered:true,
                    // }).then(()=>{
                      modifyOperationPlan({operation_plan_id:res.item[0].operation_plan_id,status:0}).then(res => {
                        if (res && res.returncode == '0') {
                          params.status=1
                          addOperationPlan(params).then(res => {
                            this.hideLoading();
                            if (res && res.returncode == '0') {
                              this.$message.success('操作成功');
                              this.$emit('get-operation-result', 'success', '操作成功');
                              this.modalVisible = false;
                              this.isConfirm=false
                            } else {
                              this.$message.error(res.errormsg || '操作失败');
                            }
                          })
                        }
                      })
                    // }).catch(()=>{
                    // });
                  }else{
                    this.showLoading();
                    addOperationPlan(params).then(res => {
                      this.hideLoading();
                      if (res && res.returncode == '0') {
                        this.$message.success('操作成功');
                        this.$emit('get-operation-result', 'success', '操作成功');
                        this.modalVisible = false;
                        this.isConfirm=false
                      } else {
                        this.$message.error(res.errormsg || '操作失败');
                      }
                    })
                  }
                }
              })
            } else if(this.showType == 'edit') {
              this.showLoading();
              getOperationPlanListByCondition({monitorpointnum:this.formData.monitorpointnum,equipment_num:this.formData.equipment_num,status:1}).then(res => {
                if (res && res.returncode == '0') {
                  if(res&&res.item.length>0) {
                    modifyOperationPlan({operation_plan_id: res.item[0].operation_plan_id, status: 0}).then(res => {
                      if (res && res.returncode == '0') {
                        params.status = 1
                        addOperationPlan(params).then(res => {
                          this.hideLoading();
                          if (res && res.returncode == '0') {
                            this.$message.success('操作成功');
                            this.$emit('get-operation-result', 'success', '操作成功');
                            this.modalVisible = false;
                            this.isConfirm=false
                          } else {
                            this.$message.error(res.errormsg || '操作失败');
                          }
                        })
                      }
                    })
                  }else{
                    this.showLoading();
                    addOperationPlan(params).then(res => {
                      this.hideLoading();
                      if (res && res.returncode == '0') {
                        this.$message.success('操作成功');
                        this.$emit('get-operation-result', 'success', '操作成功');
                        this.modalVisible = false;
                        this.isConfirm=false
                      } else {
                        this.$message.error(res.errormsg || '操作失败');
                      }
                    })
                  }
                }
              })
            }else{
              this.$message.warning('请确认信息填写完整')
            }
          } else {
            this.$message.warning('请确认信息填写完整')
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
    getEquipDirectories(){
      getEquipDirectoriesListByCondition({monitorpointnum:this.formData.monitorpointnum,equipment_num: this.formData.equipment_num}).then(res=>{
        if(res&&res.returncode=='0'){
          this.formData.directories_id=res.item[0].directories_id
          this.formData.equipment_type=res.item[0].equipment_type
          this.formData.purpose=res.item[0].purpose
        }
      })
    },
    getEquipDirectoriesList(equipment_type){
      getEquipDirectoriesList({equipment_type:equipment_type,is_history:'0'}).then(res=>{
        if(res&&res.returncode=='0'){
          this.equipmentNumList=res.item
        }
      })
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  }
}
</script>
<style lang="scss" scoped>
.select-drop-down-platform {
  width: 240px;
}
</style>