<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="设备类型" prop="equipment_type" style="width: 25%">
              <a-select v-model="queryParams.equipment_type" @change="getEquipmentCategory(queryParams.equipment_type)">
                <a-select-option v-for="(item, index) in deviceCodeList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="设备名称" prop="equipment_name" style="width: 25%">
              <a-input v-model="queryParams.equipment_name"></a-input>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
            <div v-show="showAdvanced" class="advanced-query">
              <a-form-model-item label="设备编号" prop="equipment_num" style="width: 25%">
                <a-input v-model="queryParams.equipment_num"></a-input>
              </a-form-model-item>
              <a-form-model-item label="设备类别" prop="equipment_category" style="width: 25%">
                <a-select v-model="queryParams.equipment_category" @change="getEquipmentChildCategory(queryParams.equipment_category)">
                  <a-select-option v-for="(item, index) in equipmentCategoryList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="设备子类别" prop="equipment_child_category" style="width: 25%">
                <a-select v-model="queryParams.equipment_child_category">
                  <a-select-option v-for="(item, index) in equipmentChildCategoryList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="是否为消防设备" prop="is_fire_equipment" style="width: 25%">
                <a-select v-model="queryParams.is_fire_equipment">
                  <a-select-option value="是">是</a-select-option>
                  <a-select-option value="否">否</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="设备级别" prop="equipment_level" style="width: 25%">
                <a-select v-model="queryParams.equipment_level">
                  <a-select-option value="A">A</a-select-option>
                  <a-select-option value="B">B</a-select-option>
                  <a-select-option value="C">C</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="安装位置" prop="address" style="width: 25%">
                <a-input v-model="queryParams.address"></a-input>
              </a-form-model-item>
              <a-form-model-item label="防火分区" prop="fire_compartment" style="width: 25%">
                <a-select :disabled="disabled" v-model="queryParams.fire_compartment" show-search :filter-option="filterOption">
                  <a-select-option v-for="(item, index) in fireCompartmentList" :key="index" :value="item.fire_compartment">{{item.fire_compartment}}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="设备状态" prop="equipment_status" style="width: 25%">
                <a-select v-model="queryParams.equipment_status">
                  <a-select-option v-for="(item, index) in equipmentStatusList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="档案编号" prop="archives_num" style="width: 25%">
                <a-input v-model="queryParams.archives_num"></a-input>
              </a-form-model-item>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}（共{{pagination.total}}条）</h3>
          <div class="table-btns">
            <a-button v-if="btnList.includes('导出')" @click="exportWordModal" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出标识卡</a-button>
            <a-button v-if="btnList.includes('导出')" @click="exportModal" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>
            <a-button v-if="btnList.includes('粘贴')" @click="showModal('paste',copyData)" style="margin-right: 10px"><a-icon type="copy"></a-icon>粘贴</a-button>
            <a-button v-if="btnList.includes('新增')" @click="addRecord" style="margin-right: 10px" type="primary"><a-icon type="plus"></a-icon>新增</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="directories_id" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="create_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="equipment_type" slot-scope="value,record">{{value.length==2?value.substring(0,1)==0?deviceCodeList[value.replace('0','')-1].dicvalue:deviceCodeList[value-1].dicvalue:''}}</span>
          <span slot="equipment_status" slot-scope="value,record">{{equipmentStatusList[value]?equipmentStatusList[value].dicvalue:''}}</span>
          <span slot="equipment_num" slot-scope="value,record"><a-button style="margin-left: -15px" type="link" @click="showModal('detail',record,'1')">{{value}}</a-button></span>
          <span slot="equipment_category" slot-scope="value,record">{{equipmentCategoryMap[value]?equipmentCategoryMap[value]:''}}</span>
          <span slot="equipment_child_category" slot-scope="value,record">{{equipmentChildCategoryMap[value]?equipmentChildCategoryMap[value]:''}}</span>
          <span slot="device_card" slot-scope="value,record"><a-button style="margin-left: -15px" type="link" @click="showModal('detail',record,'4')">设备标识卡</a-button></span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.directories_id">详情</a-menu-item>
                <a-menu-item v-if="btnList.includes('修改')" :key="'edit-'+record.directories_id">修改</a-menu-item>
                <a-menu-item v-if="btnList.includes('复制')" :key="'copy-'+record.directories_id">复制</a-menu-item>
                <a-menu-item v-if="btnList.includes('删除')" :key="'delete-'+record.directories_id">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :ActiveKey="activeKey" :detailData="modalDetailData" @get-operation-result="getTableData"></add-or-edit-modal>
    <create-operation-plan :visible.sync="planModalVisible" :show-type="planModalShowType" :detailData="planModalDetailData"></create-operation-plan>
    <a-modal :visible.sync="qrcodeModalVisible" :width="250" :closable="false">
      <template slot="footer">
        <a-button @click="qrcodeModalVisible=false">关闭</a-button>
        <a-button type="primary" @click="downloadFile()">下载</a-button>
      </template>
      <a id="downloadQrcode"><div id="qrCode" ref="qrCodeDiv"></div></a>
    </a-modal>
  </page-container>
</template>
<script>
import moment from "moment";
import {mapGetters, mapState} from "vuex";
import pagination from "@/mixins/pagination";
import {getCache, getItemFromArrayByKey, getTimeRange} from "U/index";
import deptselect from "@/mixins/deptselect";
import {
  exportEquipDirectoriesExcel, exportEquipmentIDCardWord,
  getEquipDirectoriesListByCondition, modifyEquipDirectories
} from "A/facilityandequipment";
import addOrEditModal from "V/facilityAndEquipment/basicManagement/directories/addOrEditModal";
import createOperationPlan from "V/facilityAndEquipment/runManagement/createOperationPlan";
import {getSecondaryDeviceList, getThirdDeviceList, getUserdepListByCondition} from "A/jcgn";
import QRCode from "qrcodejs2";
import {getMaintenanceScheduleConditionList} from "A/maintenance";
export default {
  name: "directories",
  mixins: [deptselect,pagination],
  components: {
    addOrEditModal,
    createOperationPlan,
  },
  data() {
    return {
      moment,
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      disabled:true,
      queryParams:{
        monitorpointname:'',
        equipment_type:'',
        equipment_num:'',
        equipment_name:'',
        equipment_category:'',
        equipment_child_category:'',
        fire_compartment:'',
        is_fire_equipment:'',
        archives_num:'',
        equipment_level:'',
        purpose:'',
        address:'',
        equipment_status:'',
        qrcode:'',
      },
      tableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '设备编号',
          dataIndex: 'equipment_num',
          key: 'equipment_num',
          ellipsis: true,
          scopedSlots: { customRender: 'equipment_num' },
        },
        {
          title: '设备名称',
          dataIndex: 'equipment_name',
          key: 'equipment_name',
          ellipsis: true,
        },
        {
          title: '设备类型',
          dataIndex: 'equipment_type',
          key: 'equipment_type',
          ellipsis: true,
          align:'center',
          width: 80,
          scopedSlots: { customRender: 'equipment_type' },
        },
        {
          title: '设备类别',
          dataIndex: 'equipment_category',
          key: 'equipment_category',
          ellipsis: true,
          width: 100,
          scopedSlots: { customRender: 'equipment_category' },
        },
        {
          title: '设备子类别',
          dataIndex: 'equipment_child_category',
          key: 'equipment_child_category',
          ellipsis: true,
          width: 100,
          scopedSlots: { customRender: 'equipment_child_category' },
        },
        {
          title: '消防设备',
          dataIndex: 'is_fire_equipment',
          key: 'is_fire_equipment',
          ellipsis: true,
          align:'center',
          width: 80,
          scopedSlots: { customRender: 'is_fire_equipment' },
        },
        {
          title: '防火分区',
          dataIndex: 'fire_compartment',
          key: 'fire_compartment',
          ellipsis: true,
          align:'center',
          width: 80,
        },
        {
          title: '档案编号',
          dataIndex: 'archives_num',
          key: 'archives_num',
          ellipsis: true,
          width: 80,
        },
        {
          title: '设备级别',
          dataIndex: 'equipment_level',
          key: 'equipment_level',
          ellipsis: true,
          align:'center',
          width: 80,
          scopedSlots: { customRender: 'equipment_level' },
        },
        {
          title: '用途',
          dataIndex: 'purpose',
          key: 'purpose',
          ellipsis: true,
          width: 80,
        },
        {
          title: '安装位置',
          dataIndex: 'address',
          key: 'address',
          ellipsis: true,
          width: 80,
        },
        {
          title: '设备状态',
          dataIndex: 'equipment_status',
          key: 'equipment_status',
          ellipsis: true,
          align:'center',
          width: 80,
          scopedSlots: { customRender: 'equipment_status' },
        },
        {
          title: '设备标识卡',
          dataIndex: 'device_card',
          key: 'device_card',
          ellipsis: true,
          width:100,
          scopedSlots: { customRender: 'device_card' },
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'operation' },
          width: 50
        },
      ],
      copyData:{},
      userdepidCascaderSelected: [],
      deviceCodeList:[],
      equipmentStatusList:[],
      equipmentCategoryMap:{},
      equipmentCategoryList:[],
      equipmentChildCategoryList:[],
      equipmentChildCategoryMap:[],
      fireCompartmentList:[],
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      activeKey:'1',
      planModalVisible: false,
      planModalShowType: '',
      planModalDetailData: null,
      qrcode:'',
      qrcodeModalVisible:false,

      //操作按钮权限
      btnList:[],
      menu:{},
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['operationMenuTree']),
    pageTitle() {
      return "设施设备名录"
    },
    breadcrumb() {
      const pages = [{name:"设施设备管理",path:""}]
      pages.push({name:"设施设备基础管理",path:""})
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch:{
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
        getUserdepListByCondition({userdepid:this.queryParams.userdepsid}).then(res=>{
          if(res&&res.returncode==='0'){
            if(res.item[0].levelid==='15'){
              this.getQueryCondition()
              this.disabled=false
            }
          }
        })
      }else {
        this.queryParams.userdepsid = '';
        this.disabled=true
      }
    },
  },
  created() {
    this.menu=getItemFromArrayByKey(this.operationMenuTree,"menuid",this.$route.params.menuid,"children")
    for(let i=0;i<this.menu.children.length;i++){
      this.btnList.push(this.menu.children[i].menuname)
    }
    this.getTableData()
    this.initDeptOptionsAll();
    this.getQueryCondition();
    let dictionary = getCache('dictionary', true);
    if(dictionary) {
      this.deviceCodeList = dictionary.deviceCodeList
      this.equipmentStatusList = dictionary.equipmentStatusList
      this.equipmentCategoryList = dictionary.equipmentCategoryList
      this.equipmentCategoryMap = dictionary.equipmentCategoryMap
      this.equipmentChildCategoryList = dictionary.equipmentChildCategoryList
      this.equipmentChildCategoryMap = dictionary.equipmentChildCategoryMap
    }
  },
  methods:{
    getQueryCondition(){
      getMaintenanceScheduleConditionList({userdepsid:this.queryParams.userdepsid}).then(res=>{
        if(res && res.returncode == '0') {
          this.fireCompartmentList=res.fireCompartmentList
        }
      })
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        is_history:'0',
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getEquipDirectoriesListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    resetQueryParams() {
      this.disabled=true
      this.userdepidCascaderSelected=[]
      this.$refs.queryForm.resetFields();
      let dictionary = getCache('dictionary', true);
      if(dictionary) {
        this.deviceCodeList = dictionary.deviceCodeList
        this.equipmentStatusList = dictionary.equipmentStatusList
        this.equipmentCategoryList = dictionary.equipmentCategoryList
        this.equipmentChildCategoryList = dictionary.equipmentChildCategoryList
      }
    },
    operationClick({key}){
      let arr = key.split('-');
      let type = arr[0];
      let directories_id = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'directories_id', directories_id);
      if(type == 'delete') {
        this.deleteConfirm(directories_id, record)
      }else if(type == 'createPlan') {
        this.planModalDetailData=record
        this.planModalShowType='add'
        this.planModalVisible=true
      }else if(type == 'copy'){
        this.copyData=record
        this.$message.success("数据已经复制完成")
      }else{
        this.showModal(type,record,'1')
      }
    },
    showModal(type,record,activeKey){
      if(type=='detail'){
        this.modalDetailData=record
        this.modalShowType=type
        this.activeKey=activeKey
        this.modalDetailData.btnList=this.btnList
        this.modalVisible=true
      }else if(type=='edit'){
        this.modalDetailData=record
        this.modalShowType=type
        this.activeKey=activeKey
        this.modalDetailData.btnList=this.btnList
        this.modalVisible=true
      }else if(type == 'paste') {
        this.modalDetailData = record
        this.modalShowType = type
        this.activeKey=activeKey
        this.modalDetailData.btnList=this.btnList
        this.modalVisible = true
      }
    },
    addRecord(){
      this.modalVisible=true
      this.modalShowType='add'
    },
    exportModal(){
      this.$confirm('你确认要按照所选筛选条件导出文件吗？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.exportEquipDirectories()
      }).catch(()=>{
      });
    },
    exportEquipDirectories(){
      let params = {
        ...this.queryParams,
        is_history:'0',
      }
      this.showLoading();
      exportEquipDirectoriesExcel(params).then((res)=> {
        this.hideLoading();
        this.$message.success("导出成功")
      }).catch(()=>{
        this.hideLoading();
      })
    },
    exportWordModal(){
      this.$confirm('你确认要按照所选筛选条件批量导出标识卡文件吗？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.batchExport()
      }).catch(()=>{
      });
    },
    batchExport() {
      let params = {...this.queryParams}
      this.showLoading();
      getEquipDirectoriesListByCondition(params).then((res) => {
        this.hideLoading();
        if (res && res.returncode == "0") {
          for (let i = 0; i < res.item.length; i++) {
            this.exportEquipmentIDCard(res.item[i])
          }
        }
      }).catch(() => {
        this.hideLoading();
      })
    },
    exportEquipmentIDCard(record){
      let params={
        ...record
      }
      exportEquipmentIDCardWord(params).then(res=>{
        if(res&&res.returncode=='0'){
          this.$message.success("操作成功")
        }
      })
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据?',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.directories_id);
      }).catch(()=>{

      }) ;
    },
    delete(directories_id) {
      this.showLoading();
      if(directories_id) {
        let params = {
          directories_id,
          is_history:'1',
        };
        modifyEquipDirectories(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    deleteBatch() {
      if(this.batchrowids) {
        this.deleteBatchConfirm();
      }else {
        this.$message.warning('请先选择删除项');
      }
    },
    deleteBatchConfirm() {
      this.$confirm('确定要删除选中数据?',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(this.batchrowids);
      }).catch(()=>{

      }) ;
    },
    rowSelectChange(selectedRowKeys, selectedRows) {
      this.batchrowids = selectedRowKeys.join('|');
    },
    getEquipmentCategory(devicecode){
      getSecondaryDeviceList({devicecode:devicecode}).then(res=>{
        if(res&&res.returncode=='0'){
          this.equipmentCategoryList=res.item
        }
      })
    },
    getEquipmentChildCategory(devicecode){
      getThirdDeviceList({devicecode:devicecode}).then(res=>{
        if(res&&res.returncode=='0'){
          this.equipmentChildCategoryList=res.item
        }
      })
    },
    qrcodeWatch(record){
      this.qrcodeModalVisible=true
      this.qrcode=record.equipment_name
      this.$nextTick(function () {
        this.bindQRCode(record);
      })
    },
    bindQRCode: function (record) {
      if(this.$refs.qrCodeDiv){
        this.$refs.qrCodeDiv.innerHTML = "" //清除上一个二维码
      }
      new QRCode(this.$refs.qrCodeDiv, {
        text: record.monitorpointnum + '-' + record.equipment_num,
        width: 200,
        height: 200,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
      })
    },
    downloadFile(fileName, content) {
      let qrCodeCanvas = document
          .getElementById("qrCode")
          .getElementsByTagName("canvas");
      let a = document.createElement("a");
      a.href = qrCodeCanvas[0].toDataURL("image/url");
      a.download = this.qrcode+`的二维码.png`;
      a.click();
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  },
}
</script>
<style scoped>

</style>